.discounted__wrapper {
  @extend %typo-body2;
  font-size: var(--font-size-xs);
  display: flex;
  gap: var(--spacing-10);

  @media (--small) {
    font-size: var(--font-size-s);
  }
}

.price__before__discount {
  & .discount__number {
    text-decoration: line-through;
    color: var(--color-inactive-tag);
  }
}

.price__after__discount {
  color: var(--color-red);
}

.soldOut {
  color: var(--color-inactive-tag);
}
