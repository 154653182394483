.card {
  text-decoration: none;
  color: var(--color-black);
  @extend %typo-body2;
  font-size: var(--font-size-xs);
  text-align: center;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (--small) {
    font-size: var(--font-size-s);
  }

  &__description {
    width: 100%;
    @extend %typo-body2;
    font-size: var(--font-size-xs);
    margin-top: var(--spacing-3xs);

    @media (--small) {
      font-size: var(--font-size-s);
    }

    & a {
      color: var(--color-primary);
      text-decoration: none;
    }
  }

  &__tag {
    --border-width: 1px;
    --tag-border-radius: 1000px;
    --tag-width: 99px;
    position: relative;
    background: transparent;
    color: var(--color-primary-contrast);
  }

  &__image {
    background: var(--color-tertiary);
    width: 100%;

    &__inner {
      display: none;
      position: relative;
      aspect-ratio: var(--product-aspect, 50 / 63);

      &.active {
        display: block;
      }
    }

    & img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__swatches {
    display: flex;
    justify-content: center;
    gap: var(--spacing-10);
    align-items: center;
  }

  &__swatches_more {
    color: var(--color-text-secondary);
    text-transform: uppercase;
  }
}

.tags {
  white-space: nowrap;
}

.sale {
  @extend %typo-caption1;
  background: var(--color-primary);
  color: var(--color-white);
  --sale-tag-position-left: 75%;
  --sale-tag-width: 40px;
  --sale-tag-height: 26px;
  width: var(--sale-tag-width);
  height: var(--sale-tag-height);
  position: absolute;
  top: var(--spacing-16);
  left: var(--sale-tag-position-left);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @media (--small) {
    --sale-tag-width: 45px;
  }
}

.swatch {
  height: var(--swatch-outer-size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid transparent;

  &.border {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.3);
  }

  & span {
    width: var(--swatch-inner-size);
    height: var(--swatch-inner-size);
    border-radius: 100%;
    display: block;
    background: var(--swatch-default-color);
    margin-bottom: 1px;
  }

  &.active {
    border-bottom: 1px solid var(--color-dark);
  }
}

.product__meta {
  @extend %typo-body2;
  font-size: var(--font-size-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-8);

  @media (--small) {
    font-size: var(--font-size-s);
  }

  & span {
    display: block;
  }
}

.card__image__inner {
  &:hover .product__image.main {
    opacity: 0;
  }

  &:hover .product__image.secondary {
    opacity: 1;
  }

  & .product__image {
    object-fit: contain;

    &.main,
    &.secondary {
      transition: opacity 300ms ease;
    }

    &.main {
      opacity: 1;
    }

    &.secondary {
      opacity: 0;
    }
  }
}

.related {
  & .card {
    display: flex;
    gap: var(--spacing-3xs);
    margin-bottom: var(--spacing-3xs);

    &__description {
      margin: 0;
    }

    & img {
      --image-height: 280px;
      min-height: var(--image-height);
      object-fit: cover;
    }
  }
}

.tag {
  background: var(--color-tertiary);
  border-radius: 2px;
  padding: 4px 6px;
  text-transform: uppercase;
  display: none;

  &__dark {
    background: var(--color-primary);
    color: var(--color-light);
    display: block;
  }
}

.plusIcon {
  --iconSize: 20px;
  height: var(--iconSize);

  & svg {
    --svg-width: 11px;
    --svg-height: 11px;
    width: var(--svg-width);
    height: var(--svg-height);
  }
}

.soldOutTag {
  color: var(--color-inactive-tag);
}

.commingSoonTag {
  color: var(--color-inactive-tag);
}

.onSaleTag {
  color: var(--color-red);
}

.addButton {
  height: var(--spacing-20);
  min-width: var(--spacing-20);
  max-width: var(--spacing-20);
  padding: 0;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;

  &:hover {
    background-color: var(--neutral-3);
  }
}

.addFavoriteWrapper {
  gap: var(--spacing-14);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-3xs);
}

.addFavourite,
.removeFavourite {
  align-items: center;
  display: flex;
  justify-content: center;
  & svg {
    width: var(--spacing-16);
    height: var(--spacing-16);
    --svg-color: var(--color-primary);
  }
}

.removeFavourite {
  & svg {
    fill: var(--color-primary);
  }
}
